import { useState } from "react";
import { Link } from "react-router-dom";
import { X, Menu, ChevronRight } from "lucide-react";
import { ScrollArea } from "./components/ui/scroll-area";
import Footer from "./Footer";
import Header from "./Header";
import { useEffect } from "react";
import { baseUrl } from "./baseUrl";
import { Button } from "./components/ui/button";

interface BlogPost {
    id: number;
    title: string;
    category: string;
    subtitle: string;
    created_at: string;
    imgpath: string;
    content: string;
    slug: string;
}

export default function Blog() {
    const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>("Tous");
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                setLoading(true);
                const response = await fetch(baseUrl + "/get-articles");
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                console.log(data.data)
                setBlogPosts(data.data);
            } catch (error) {
                console.error("Erreur lors du fetch des articles :", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogPosts();
    }, []);

    const categories: string[] = [...Array.from(new Set(blogPosts.map(post => post.category)))];


    const lastPost = blogPosts[0]
    // Filtrer les articles par catégorie
    const filteredPosts = selectedCategory === "Tous" 
        ? blogPosts.slice(1)
        : blogPosts.filter(post => post.category === selectedCategory);

    // Obtenir les 3 derniers articles
    const latestPosts = [...blogPosts].sort((a, b) => 
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    ).slice(0, 3);

    // Composant pour un article
    const BlogPost = ({ post }: { post: BlogPost }) => (
        <div className="relative w-full overflow-hidden rounded-xl shadow-lg transition-transform hover:scale-102 group">
            <a href={`/blog/${post.slug}`} className="block relative h-[24rem]">
                <div className="absolute inset-0">
                    <img 
                        src={post.imgpath} 
                        alt={post.title} 
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-85 transition group-hover:opacity-100" />
                </div>
                
                <div className="absolute top-4 right-4 px-3 py-1 bg-black/70 text-white text-sm rounded-full">
                    {new Date(post.created_at).toLocaleDateString('fr-FR', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                    })}
                </div>
                
                <div className="absolute bottom-0 left-0 right-0 p-6 transform transition-transform group-hover:translate-y-[-8px]">
                    <div className="space-y-3">
                        <span className="inline-block px-4 py-1 text-sm font-medium text-white bg-green-600 rounded-full">
                            {post.category}
                        </span>
                        <h3 className="text-xl font-bold text-white">
                            {post.title}
                        </h3>
                        <p className="text-md text-gray-200">
                            {post.subtitle}
                        </p>
                    </div>
                </div>
            </a>
        </div>
    );

  const token = localStorage.getItem("token") || "";
  const isAdmin = token === process.env.REACT_APP_API_KEY;

    return(
        <>
        <Header />
            <div className="pt-20 lg:pt-0">
                <div className="flex flex-col lg:flex-row gap-8">
                    <div className="lg:w-80 flex-shrink-0 lg:sticky lg:top-24 self-start pl-5">
                    <div className="bg-gray-50 rounded-xl p-6">
                            <h4 className="text-xl font-bold mb-4">Nouveaux articles</h4>
                            <div className="space-y-4">
                                {latestPosts.map(post => (
                                    <a 
                                        key={post.id}
                                        href={`/blog/${post.slug}`}
                                        className="block group"
                                    >
                                        <div className="flex gap-4 items-start">
                                            <img 
                                                src={post.imgpath} 
                                                alt={post.title}
                                                className="w-20 h-20 object-cover rounded-lg"
                                            />
                                            <div>
                                                <h3 className="font-medium group-hover:text-blue-600 transition-colors">
                                                    {post.title}
                                                </h3>
                                                <p className="text-sm text-gray-500 mt-1">
                                                    {new Date(post.created_at).toLocaleDateString('fr-FR', {
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: 'numeric'
                                                    })}
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className="bg-gray-50 rounded-xl p-6 my-5">
                            <h4 className="text-xl font-bold mb-4">Categories</h4>
                            <div className="mb-8 flex gap-2 flex-wrap">
                                <button
                                    onClick={() => setSelectedCategory("Tous")}
                                    className={`px-4 py-2 rounded-full transition ${
                                        selectedCategory === "Tous"
                                            ? "bg-blue-600 text-white"
                                            : "bg-gray-200 hover:bg-gray-300"
                                    }`}
                                >
                                    Tous
                                </button>
                                {categories.map(category => (
                                    <button
                                        key={category}
                                        onClick={() => setSelectedCategory(category)}
                                        className={`px-4 py-2 rounded-full transition ${
                                            selectedCategory === category
                                                ? "bg-blue-600 text-white"
                                                : "bg-gray-200 hover:bg-gray-300"
                                        }`}
                                    >
                                        {category}
                                    </button>
                                ))}
                            </div>
                        </div>
                        {isAdmin && (
                            <Link to="/blogedito">
                                <Button>Ajouter un article</Button>
                            </Link>
                        )}
                    </div>
                    <ScrollArea className="flex-grow h-[calc(100vh)] pt-16">
                        <div className="pt-8 px-5">
                            {selectedCategory === "Tous" && lastPost && (
                                <div className="relative w-full max-w-5xl mx-auto overflow-hidden rounded-xl shadow-lg transition-transform hover:scale-102 group mb-10">
                                    <a href={`/blog/${lastPost.slug}`}
                                        className="block relative h-[30rem]">
                                        <div className="absolute inset-0">
                                            <img 
                                                src={lastPost.imgpath} 
                                                alt={lastPost.title} 
                                                className="w-full h-full object-cover"
                                            />
                                            <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-85 transition group-hover:opacity-100" />
                                            </div>
                                            
                                            <div className="absolute top-4 right-4 px-3 py-1 bg-black/70 text-white text-sm rounded-full">
    {new Date(lastPost.created_at).toLocaleDateString('fr-FR', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    })}
</div>
                                            <div className="z-50 absolute bottom-4 right-4 px-3 py-1 text-white text-md font-bold rounded-full flex items-center space-x-1 transition group-hover:translate-y-[-8px] hover:scale-105">
                                                <span>Lire l'article</span>
                                                <ChevronRight size={24} strokeWidth={2} />
                                            </div>
                                            <div className="absolute bottom-0 left-0 right-0 p-6 transform transition-transform group-hover:translate-y-[-8px]">
                                            <div className="space-y-3">
                                                <span className="inline-block px-4 py-1 text-sm font-medium text-white bg-green-600 rounded-full">
                                                    {lastPost.category}
                                                </span>
                                                <h3 className="text-4xl font-bold text-white">
                                                    {lastPost.title}
                                                </h3>
                                                <p className="text-xl text-gray-200">
                                                    {lastPost.subtitle}
                                                </p>
                                            </div>
                                            </div>
                                        </a>
                                </div>
                            )}
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                                {filteredPosts.map(post => (
                                    <BlogPost key={post.id} post={post} />
                                ))}
                            </div>
                        </div>
                    </ScrollArea>
                </div>
            </div>
        </>
    );
}