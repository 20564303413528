import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function LoadToken() {
    const [tokenInput, setTokenInput] = useState('');

    const handleSaveToken = () => {
        localStorage.setItem('token', tokenInput);
    };

    return (
        <div className="space-x-3">
            <input 
                className="border" 
                value={tokenInput}
                onChange={(e) => setTokenInput(e.target.value)}
                placeholder="Entrez votre token"
            ></input>
            <Link to={"/blog"}>
            <button 
                className="border"
                onClick={handleSaveToken}
            >
                load
            </button>
            </Link>
        </div>
    )
}