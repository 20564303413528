import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import { Button } from './components/ui/button';
import { Bold, ImagePlus, Heading2, X, Send } from 'lucide-react';
import { Input } from './components/ui/input';
import { baseUrl } from './baseUrl';

const parseMarkdown = (text: string) => {
  return text
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    .replace(/# (.*?)(\n|$)/g, '<h1 class="text-2xl font-bold my-2">$1</h1>')
    .replace(/!\[(.*?)\]\((.*?)\)/g, '<img src="$2" alt="$1" class="max-w-full h-auto my-4 rounded">')
    .split('\n').join('<br />');
};

const safeBase64Decode = (str: string): string => {
    return decodeURIComponent(
        atob(str)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
    );
};


const Rename = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [error, setError] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [category, setCategory] = useState('');
  const token = localStorage.getItem("token") || "";
  const isAdmin = token === process.env.REACT_APP_API_KEY;
  const [content, setContent] = useState('');
  const [mainImage, setMainImage] = useState('');
  const [showImageInput, setShowImageInput] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    fetch(baseUrl + "/get-article", {
        method: 'GET',
        headers: {
            'slug': slug || "",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        setTitle(data["title"])
        setSubtitle(data["subtitle"])
        setMainImage(data["imgpath"])
        setCategory(data["category"])
        setContent(safeBase64Decode(data["content"]))
    })
    .catch(error => console.error('Error:', error));
    }, [slug]);


  const safeBase64Encode = (str: string): string => {
    return btoa(encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      (match, p1) => String.fromCharCode(parseInt(p1, 16))
    ));
  };

  const insertTextStyle = (style: string) => {
    const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
    if (textarea) {
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const selectedText = content.substring(start, end);

      if (style === 'bold') {
        setContent(content.substring(0, start) + `**${selectedText || 'Texte en gras'}**` + content.substring(end));
      } else if (style === 'big') {
        setContent(content.substring(0, start) + `# ${selectedText || 'Grand texte'}\n` + content.substring(end));
      }
    }
  };

  const insertImage = () => {
    if (imageUrl) {
      const imageMarkdown = `![Image](${imageUrl})\n`;
      setContent(content + imageMarkdown);
      setImageUrl('');
      setShowImageInput(false);
    }
  };

  const handleSubmit = async () => {
    const encodedContent = safeBase64Encode(content);

    try {
      const response = await fetch(baseUrl + '/update-article', {
        method: 'POST',
        headers: {
            'token': token,
            'slug': slug || "", // Titre de l'article à mettre à jour
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          content: encodedContent, // Nouveau contenu de l'article
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message || `HTTP error! status: ${response.status}`);
      } else {
        setError('');
        navigate('/blog');
        localStorage.setItem("token", token)
      }
    } catch (error) {
      console.error('Error updating blog article:', error);
    }
  };


  return isAdmin ? (
    <div className="max-w-4xl mx-auto p-4">
      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Modifier l'article</CardTitle>
        </CardHeader>
        <CardContent>
          <p className='text-red-500'>{error}</p>
          <div className="flex gap-2 p-2 border rounded bg-gray-50">
            <Button variant="outline" size="icon" onClick={() => insertTextStyle('bold')}>
              <Bold className="h-4 w-4" />
            </Button>
            <Button variant="outline" size="icon" onClick={() => insertTextStyle('big')}>
              <Heading2 className="h-4 w-4" />
            </Button>
            <Button variant="outline" size="icon" onClick={() => setShowImageInput(!showImageInput)}>
              <ImagePlus className="h-4 w-4" />
            </Button>
          </div>
          {showImageInput && (
            <div className="flex gap-2 items-center">
              <Input
                type="text"
                placeholder="URL de l'image"
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
                className="flex-grow"
              />
              <Button onClick={insertImage}>Insérer</Button>
              <Button variant="ghost" size="icon" onClick={() => setShowImageInput(false)}>
                <X className="h-4 w-4" />
              </Button>
            </div>
          )}
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Contenu de l'article..."
            className="w-full h-64 p-4 border rounded"
          />
          <div className="flex justify-end">
            <Button onClick={handleSubmit} className="flex gap-2 items-center">
              <Send className="h-4 w-4" />
              Mettre à jour
            </Button>
          </div>
        </CardContent>
      </Card>

      
      <Card>
        <CardHeader>
          <CardTitle>Prévisualisation</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="prose max-w-none">
            <p className="text-sm text-gray-500 mb-2">{category || "Catégorie"}</p>
            {mainImage && (
              <img 
                src={mainImage} 
                alt="Image principale" 
                className="w-full h-48 object-cover rounded mb-4"
              />
            )}
            <h1 className="text-3xl font-bold mb-2">{title || "Titre de l'article"}</h1>
            <h2 className="text-xl text-gray-600 mb-4">{subtitle || "Sous-titre"}</h2>
            <div 
              className="whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: parseMarkdown(content) || "Le contenu de votre article apparaîtra ici..." }}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  ) : (
    <Navigate to={"/blog"} />
  );
};

export default Rename;
