import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card, CardContent } from './components/ui/card';
import Footer from './Footer';
import Header from './Header';
import { baseUrl } from './baseUrl';
import { Button } from './components/ui/button';
import { Dialog, DialogFooter, DialogHeader, DialogTrigger, DialogClose, DialogContent, DialogDescription, DialogTitle } from './components/ui/dialog';

interface Article {
    id: number;
    created_at: string;
    imgpath: string;
    title: string;
    subtitle: string;
    content: string;
    category: string;
    slug: string;
}

const parseMarkdown = (text: string): string => {
    if (!text) return '';
    return text
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    .replace(/# (.*?)(\n|$)/g, '<h1 class="text-2xl font-bold my-2">$1</h1>')
    .replace(/!\[(.*?)\]\((.*?)\)/g, '<img src="$2" alt="$1" class="max-w-full h-auto my-4 rounded">')
    .split('\n').join('<br />');
};

const decodeBase64Content = (encodedContent: string): string => {
    try {
    return atob(encodedContent);
    } catch (error) {
    console.error('Erreur de décodage base64:', error);
    return 'Erreur lors du décodage du contenu';
    }
};

const safeBase64Decode = (str: string): string => {
    return decodeURIComponent(
        atob(str)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
    );
};

const BlogArticle: React.FC = () => {
    const [article, setArticle] = useState<Article | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [decodedContent, setDecodedContent] = useState<string>('');

    const token = localStorage.getItem("token") || "";
    const isAdmin = token === process.env.REACT_APP_API_KEY;
    const location = useLocation();
    const slug = location.pathname.split('/blog/')[1];

    useEffect(() => {
    const fetchArticle = async () => {
        try {
        const response = await fetch(baseUrl + '/get-article', {
            headers: {
            'slug': slug,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            }
        });

        const data: Article = await response.json();
        
        // Décodage du contenu base64
        const decoded = safeBase64Decode(data.content);
        setDecodedContent(decoded);
        setArticle({
            ...data,
            content: decoded
        });
        setError(null);
        } catch (err) {
        console.error('Erreur complète:', err);
        setError(err instanceof Error ? err.message : 'Une erreur est survenue');
        } finally {
        setLoading(false);
        }
    };

    if (slug) {
        setLoading(true);
        fetchArticle();
    }
    }, [slug]);

    if (loading) {
    return (
        <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-primary border-t-transparent"></div>
        </div>
    );
    }

    if (error) {
    return (
        <div className="max-w-4xl mx-auto p-4">
        <Card className="bg-red-50">
            <CardContent className="p-6 text-center text-red-600">
            <p className="font-semibold mb-2">Erreur</p>
            <p>{error}</p>
            </CardContent>
        </Card>
        </div>
    );
    }

    if (!article) {
    return (
        <div className="max-w-4xl mx-auto p-4">
        <Card>
            <CardContent className="p-6 text-center">
            Article non trouvé
            </CardContent>
        </Card>
        </div>
    );
    }
    const handleDelete = async () => {
        console.log(slug)
        const response = await fetch(baseUrl + '/delete-article', {
            method: 'POST',
            headers: {
                'token': token,
                'slug': slug || "", // Titre de l'article à mettre à jour
                'Content-Type': 'application/json'
            }
          });

          console.log(response)
    }
    

    return (
        <>
        <Header />
            <div className="max-w-4xl mx-auto p-4 pb-16 pt-20">
                {isAdmin && (
                    <div className='flex space-x-3 pb-3'>
                        <Link to={`/blogchange/${slug}`}><Button>Modifier l'article</Button></Link>
                        <Dialog>
                            <DialogTrigger asChild>
                                <Button variant={"destructive"}>Supprimer l'article</Button>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-md">
                                <DialogHeader>
                                <DialogTitle>Supprimer l'article</DialogTitle>
                                <DialogDescription>
                                    En appuyant sur supprimer, l'article sera supprimé
                                </DialogDescription>
                                </DialogHeader>
                                <DialogFooter className="sm:justify-start">
                                    <Link to={"/blog"}>
                                        <Button type="button" variant="destructive" onClick={handleDelete}>
                                            Supprimer
                                        </Button>
                                    </Link>
                                    <DialogClose asChild>
                                        <Button type="button" variant="secondary">
                                        Close
                                        </Button>
                                    </DialogClose>
                                </DialogFooter>
                            </DialogContent>
                        </Dialog>
                    </div>
                )}
                
                <div className='flex justify-between mb-10'>
                    <span className="px-4 py-1 text-sm font-medium text-white bg-green-600 rounded-full">
                        {article.category}
                    </span>

                    <span className="px-3 py-1 bg-black/70 text-white text-sm rounded-full">
                        {new Date(article.created_at).toLocaleDateString('fr-FR', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                        })}
                    </span>
                </div>

                <div className='text-center py-5'>
                    <h1 className="text-6xl font-bold mb-4">
                    {article.title}
                    </h1>

                    <h2 className="text-2xl text-gray-600 mb-8">
                    {article.subtitle}
                    </h2>
                </div>

                {article.imgpath && (
                <div className="mb-6">
                    <img
                    src={article.imgpath}
                    alt={article.title}
                    className="w-full h-64 object-cover rounded-lg"
                    />
                </div>
                )}

                

                <div 
                className="prose max-w-none"
                dangerouslySetInnerHTML={{ 
                    __html: parseMarkdown(article.content)
                }}
                />
        </div>
    <Footer />
    </>
    );
};

export default BlogArticle;