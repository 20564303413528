import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import { Button } from './components/ui/button';
import { Bold, ImagePlus, Heading2, X, Send } from 'lucide-react';
import { Input } from './components/ui/input';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { baseUrl } from './baseUrl';

const parseMarkdown = (text: string) => {
  return text
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    .replace(/# (.*?)(\n|$)/g, '<h1 class="text-2xl font-bold my-2">$1</h1>')
    .replace(/!\[(.*?)\]\((.*?)\)/g, '<img src="$2" alt="$1" class="max-w-full h-auto my-4 rounded">')
    .split('\n').join('<br />');
};

const BlogEditor = () => {
  const [title, setTitle] = useState('');
  const [error, setError] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [category, setCategory] = useState('');
  const token = localStorage.getItem("token") || "";
  const isAdmin = token === process.env.REACT_APP_API_KEY;  const [content, setContent] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [mainImage, setMainImage] = useState('');
  const [showImageInput, setShowImageInput] = useState(false);

  const insertTextStyle = (style: any) => {
    const textarea = document.querySelector('textarea');
    if (textarea) {
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const selectedText = content.substring(start, end);
        
        switch (style) {
        case 'bold':
            const newText = content.substring(0, start) + 
                        `**${selectedText || 'Texte en gras'}**` + 
                        content.substring(end);
            setContent(newText);
            break;
        case 'big':
            const bigText = content.substring(0, start) + 
                        `# ${selectedText || 'Grand texte'}\n` + 
                        content.substring(end);
            setContent(bigText);
            break;
        }
    }
  };

  const insertImage = () => {
    if (imageUrl) {
      const imageMarkdown = `![Image](${imageUrl})\n`;
      setContent(content + imageMarkdown);
      setImageUrl('');
      setShowImageInput(false);
    }
  };

  const safeBase64Encode = (str: string): string => {
      return btoa(encodeURIComponent(str).replace(
          /%([0-9A-F]{2})/g,
          (match, p1) => String.fromCharCode(parseInt(p1, 16))
      ));
  };

  const createSlug = () => {
    return title
      .toLowerCase() // Met en minuscules
      .normalize('NFD') // Normalise les caractères (décompose les caractères accentués)
      .replace(/[\u0300-\u036f]/g, '') // Enlève les accents
      .replace(/[^a-z0-9\s-]/g, '') // Garde uniquement les lettres, chiffres, espaces et tirets
      .replace(/\s+/g, '-') // Remplace les espaces par des tirets
      .replace(/-+/g, '-') // Évite les tirets multiples
      .replace(/^-+|-+$/g, ''); // Enlève les tirets au début et à la fin
  };

  const navigate = useNavigate();

  const handleSubmit = async () => {
    // Ici vous pouvez ajouter la logique pour envoyer les données à votre API
    const encodedContent = safeBase64Encode(content); // Encode en base64
    
    try {
      const response = await fetch(baseUrl + '/add-article', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          token: token,
          category: category,
          title: title,
          subtitle: subtitle,
          image: mainImage,
          content: encodedContent,
          slug: createSlug()
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.message || `HTTP error! status: ${response.status}`)
      }
      else {
        const data = await response.json();
        setError("")
        localStorage.setItem("token", token)
        navigate("/blog")
        return data;
      }
    } catch (error) {
      console.error('Error adding blog article:', error);
    }
  };

  return isAdmin ? (
    <div className="max-w-4xl mx-auto p-4">
      <Card className="mb-8">
        <CardHeader>
          <CardTitle>Nouvel Article</CardTitle>
        </CardHeader>
        <CardContent>
          <p>pour les images: aller sur <Link to={"https://www.pexels.com/fr-fr/"} target="about:blank" className='bg-blue-500 underline'>ce site</Link>, chercher une image puis clic droit, copier l'adresse de l'image</p>
          <div className="space-y-6">
            <p className='text-red-500'>{error}</p>
            <Input
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              placeholder="Catégorie"
              className="w-full"
            />

            <div>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Titre de l'article"
                className="w-full px-4 py-2 text-2xl font-bold border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <input
                type="text"
                value={subtitle}
                onChange={(e) => setSubtitle(e.target.value)}
                placeholder="Sous-titre"
                className="w-full px-4 py-2 text-xl border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <Input
                type="text"
                value={mainImage}
                onChange={(e) => setMainImage(e.target.value)}
                placeholder="URL de l'image principale"
                className="w-full"
              />
            </div>

            <div className="flex gap-2 p-2 border rounded bg-gray-50">
              <Button
                variant="outline"
                size="icon"
                onClick={() => insertTextStyle('bold')}
                title="Texte en gras (sélectionnez du texte d'abord)"
              >
                <Bold className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="icon"
                onClick={() => insertTextStyle('big')}
                title="Grand texte (sélectionnez du texte d'abord)"
              >
                <Heading2 className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="icon"
                onClick={() => setShowImageInput(!showImageInput)}
                title="Insérer une image dans le contenu"
              >
                <ImagePlus className="h-4 w-4" />
              </Button>
            </div>

            {showImageInput && (
              <div className="flex gap-2 items-center">
                <Input
                  type="text"
                  placeholder="Collez l'URL de l'image à insérer dans le contenu"
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
                  className="flex-grow"
                />
                <Button onClick={insertImage} disabled={!imageUrl}>
                  Insérer
                </Button>
                <Button 
                  variant="ghost" 
                  size="icon"
                  onClick={() => {
                    setShowImageInput(false);
                    setImageUrl('');
                  }}
                >
                  <X className="h-4 w-4" />
                </Button>
              </div>
            )}

            <div>
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Contenu de l'article..."
                className="w-full h-64 p-4 border rounded focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div className="flex justify-end">
              <Button 
                onClick={handleSubmit}
                className="flex gap-2 items-center"
              >
                <Send className="h-4 w-4" />
                Publier l'article
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Prévisualisation</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="prose max-w-none">
            <p className="text-sm text-gray-500 mb-2">{category || "Catégorie"}</p>
            {mainImage && (
              <img 
                src={mainImage} 
                alt="Image principale" 
                className="w-full h-48 object-cover rounded mb-4"
              />
            )}
            <h1 className="text-3xl font-bold mb-2">{title || "Titre de l'article"}</h1>
            <h2 className="text-xl text-gray-600 mb-4">{subtitle || "Sous-titre"}</h2>
            <div 
              className="whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: parseMarkdown(content) || "Le contenu de votre article apparaîtra ici..." }}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  ) : (
    <Navigate to={"/blog"} />
  );
};

export default BlogEditor;